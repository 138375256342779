import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private records: any[] = [];
  private baseUrl = "https://sammy.duapa.online/"
  // private baseUrl = "https://acs-api.ghanalibrary.org/"
  private options;

  constructor(private http: HttpClient, private router: Router, private authService: AuthenticationService) {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    };
  }

  getRecords() {
    return new Promise(resolve => {
      this.http.get(this.baseUrl + 'records', this.options).subscribe(data => {
        resolve(data)
      }, err => {
        // this.router.navigateByUrl('auth')
        console.log(err)
      })
    })
  }



  createNewRecord(recordObj) {
    return new Promise(resolve => {
      this.http.post(this.baseUrl + 'records/new', recordObj, this.options).subscribe(response => {
        resolve(response)
      })
    }).catch(err => {
      console.log(err)
      return err
    })
  }

  createSupRecord(recordObj) {
    return new Promise(resolve => {
      this.http.post(this.baseUrl + 'records/supplementary', recordObj, this.options).subscribe(response => {
        resolve(response)
      })
    }).catch(err => {
      console.log(err)
      return err
    })
  }

  deductRecord(recordObj) {
    return new Promise(resolve => {
      this.http.post(this.baseUrl + 'records/debit', recordObj, this.options).subscribe(response => {
        resolve(response)
      })
    }).catch(err => {
      console.log(err)
      return err
    })
  }


  getBarcodes(recordObj) {
    return new Promise(resolve => {
      this.http.post(this.baseUrl + 'record/barcodes', recordObj, this.options).subscribe(response => {
        resolve(response)
      })
    }).catch(err => {
      return err
    })
  }

  regenerateBarcodes(recordObj) {
    return new Promise(resolve => {
      this.http.post(this.baseUrl + 'record/barcode/regenerate', recordObj, this.options).subscribe(response => {
        resolve(response)
      })
    }).catch(err => {
      return err;
    })
  }


  makeEditToRecord(recordObj) {
    return new Promise(resolve => {
      this.http.post(this.baseUrl + 'records/edit', recordObj, this.options).subscribe(res => {
        resolve(res)
      })
    })
  }

  markPrinted(recordObj) {
    return new Promise(resolve => {
      this.http.post(this.baseUrl + 'record/printed', recordObj, this.options).subscribe(res => {
        resolve(res)
      })
    })
  }
}
