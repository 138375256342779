import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { MainComponent } from './main/main.component';


const routes: Routes = [
  {
    path: "",
    redirectTo: "/auth",
    pathMatch: 'full'
  },
  {
    path: "auth",
    loadChildren: ()=>import('./auth/auth.module').then(m=>m.AuthModule)
  },
  {
    path: "home",
    loadChildren: ()=>import('./main/main.module').then(m=>m.MainModule)
  },
  {
    path:"**",
    redirectTo:"/auth",
    pathMatch:'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{enableTracing:false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
