import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { resolve } from 'url';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private token;
  private user;
  options;
  // private baseUrl = "https://acs-api.ghanalibrary.org/"
  private baseUrl = "https://sammy.duapa.online/"

  constructor(private http: HttpClient) {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token')
      })
    };
  }


  authenticate(userObj) {
    return new Promise(resolve => {
      this.http.post(this.baseUrl + 'auth', userObj).subscribe(res => {
        let payload: any = res as Object
        this.token = payload.token
        this.user = payload.user
        localStorage.setItem('token',this.token)
        localStorage.setItem('user',this.user)
        localStorage.setItem('place_entery',payload.place_entery)
        resolve(true)
      }, err => {
        resolve(false)
      })
    })
  }

  isValidToken(){
    return new Promise(resolve=>{
      this.http.get(this.baseUrl+'/validate/token',this.options).subscribe(res=>{
        resolve(res)
      })
    })
  }

  isTokenPresent() {
    return this.token !== null
  }

  getToken(){
    return this.token;
  }

  getUser(){
    return localStorage.getItem('user')
  }

  getUid(){
    return localStorage.getItem('uid')
  }


  getRole(){
    return localStorage.getItem('place_entery')
  }

}
